import { graphql } from 'gatsby';
import React, { FC } from 'react';

import { Container, Row, Col, Layout } from 'layout';
import Banner from 'components/Banner';
import ArticleCard from 'components/ArticleCard';
import SuccessfulSexPageNavigation from 'components/SuccessfulSexPageNavigation/SuccessfulSexPageNavigation';

import { ISuccessfulSexPageTrendingTopicsComponentProps } from './models';
import './SuccessfulSexPageTrendingTopics.scss';

const SuccessfulSexPageTrendingTopics: FC<ISuccessfulSexPageTrendingTopicsComponentProps> = ({
  data: {
    umbracoSuccessfulSexTrendingTopics: {
      banner,
      sideNavigation,
      defaultCompositions,
      seoMetaKeywords,
      seoMetaTitle,
      seoMetaDescription,
      seoExternalHreflangs,
      url,
    },
    successfulSexArticles,
  },
}) => {
  return (
    <Layout
      defaultCompositions={defaultCompositions}
      seo={{
        seoMetaKeywords,
        seoMetaTitle,
        seoMetaDescription,
        seoExternalHreflangs,
      }}
      className="successful-sex-trending-topics"
    >
      <Container fluid>
        {banner.map(({ properties: { title, ...restBannerProperties } }) => (
          <Banner
            key={title}
            url={url}
            title={title}
            {...{ ...restBannerProperties, image: restBannerProperties.localImage }}
          />
        ))}
        <Container>
          <div className="container__inside">
            <Row>
              <Col lg="4" xl="4">
                <SuccessfulSexPageNavigation {...{ sideNavigation }} />
              </Col>
              <Col sm="12" md="12" lg="8" xl="8">
                <div className="successful-sex-trending-topics__articles">
                  {successfulSexArticles.nodes?.map((article) => (
                    <ArticleCard key={article.id} article={article} />
                  ))}
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </Container>
    </Layout>
  );
};

export default SuccessfulSexPageTrendingTopics;

export const query = graphql`
  query SuccessfulSexPageTrendingTopics($url: String = "", $tagsID: Int = 0) {
    umbracoSuccessfulSexTrendingTopics(url: { eq: $url }) {
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
      seoExternalHreflangs {
        hreflang: key
        href: value
      }
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      pageName
      url
      banner {
        properties {
          title
        }
      }
      sideNavigation {
        properties {
          sideNavigationName
          sideNavigationMenu {
            url
            name
          }
        }
      }
    }
    successfulSexArticles: allUmbracoArticles(
      filter: {
        properties: { tags: { elemMatch: { url: { elemMatch: {} }, id: { eq: $tagsID } } } }
      }
    ) {
      nodes {
        id
        title
        properties {
          title
          tags {
            name
            url {
              url
              name
            }
          }
          articleBody {
            structure
            properties {
              articleIntroductionText
            }
          }
          articleHeroBannerMainImageAltText
        }
        url
        localImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
